import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Oval } from "react-loader-spinner"; // Import loader
import { useNavigate } from "react-router-dom";

const Registration = () => {
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false); // State for toggling password visibility
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false); // Separate toggle for confirmPassword
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    termsAndCondition: false,
  });

  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "password") {
      validatePassword(value);
    } else if (name === "confirmPassword") {
      validateConfirmPassword(value, formData.password);
    }
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const maxLength = 64;
    const regexNumber = /\d/; // At least one number
    const regexLowerCase = /[a-z]/; // At least one lowercase
    const regexUpperCase = /[A-Z]/; // At least one uppercase
    const regexSpecialChar = /[@#$%^&*(),.?":{}|<>]/; // At least one special character

    let errorMessage = "";

    if (password.length < minLength || password.length > maxLength) {
      errorMessage = `Password must be between ${minLength}-${maxLength} characters.`;
    } else if (!regexNumber.test(password)) {
      errorMessage = "Password must contain at least one number.";
    } else if (!regexLowerCase.test(password)) {
      errorMessage = "Password must contain at least one lowercase letter.";
    } else if (!regexUpperCase.test(password)) {
      errorMessage = "Password must contain at least one uppercase letter.";
    } else if (!regexSpecialChar.test(password)) {
      errorMessage = "Password must contain at least one special character.";
    }

    setErrors((prev) => ({ ...prev, password: errorMessage }));
  };

  const validateConfirmPassword = (confirmPassword, password) => {
    const errorMessage =
      confirmPassword !== password ? "Passwords do not match." : "";
    setErrors((prev) => ({ ...prev, confirmPassword: errorMessage }));
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleSubmit = async (e) => {
    setIsLoading(true); // Set loading state to true
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://backend.inyumba.co.ke/iNyumba/auth/register",
        formData,
        {
          params: {
            accountType: "BUSINESS",
          },
        }
      );
      // Display success message
      toast.success(
        "Registration is successful. Kindly check your email to complete verification!"
      );

      setIsLoading(false); // Set loading state to false

      // Redirect to the login page
      navigate("/");
    } catch (error) {
      console.error(error); // Display error message

      if (error.response && error.response.status === 401) {
        setIsLoading(false); // Set loading state to false
        toast.error("Unauthorized access");
      } else if (error.response && error.response.status === 406) {
        setIsLoading(false); // Set loading state to false
        toast.error(
          "Wrong password format. Have a Number, Capital and Small letter and Special Character i.e @{}etc example P@ssw0rd10{}"
        );
      } else {
        setIsLoading(false); // Set loading state to false
        toast.error("An error occurred, please try again later");
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Registration
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="name" className="sr-only">
                Name
              </label>
              <input
                id="name"
                name="name"
                type="text"
                autoComplete="name"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="phoneNumber" className="sr-only">
                Phone Number
              </label>
              <input
                id="phoneNumber"
                name="phoneNumber"
                type="tel"
                autoComplete="tel"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Phone Number"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </div>
            <div className="relative">
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type={passwordVisible ? "text" : "password"}
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-3 top-2 text-sm text-gray-600 focus:outline-none"
                aria-label="Toggle password visibility"
              >
                {passwordVisible ? "Hide" : "Show"}
              </button>
            </div>
            <div className="relative">
              <label htmlFor="confirmPassword" className="sr-only">
                Confirm Password
              </label>
              <input
                id="confirmPassword"
                name="confirmPassword"
                type={passwordVisible ? "text" : "password"}
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <label
              htmlFor="termsAndCondition"
              className="flex items-center text-sm text-gray-600"
            >
              <input
                id="termsAndCondition"
                name="termsAndCondition"
                type="checkbox"
                checked={formData.termsAndCondition}
                onChange={() =>
                  setFormData({
                    ...formData,
                    termsAndCondition: !formData.termsAndCondition,
                  })
                }
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <span className="ml-2">Agree to terms and conditions</span>
            </label>
          </div>
          <div>
            <button
              type="submit"
              disabled={isLoading || !formData.termsAndCondition}
              className={`${
                isLoading || !formData.termsAndCondition
                  ? "bg-gray-500 cursor-not-allowed"
                  : "bg-indigo-600 hover:bg-indigo-700"
              } group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              {isLoading ? (
                <Oval
                  height={20}
                  width={20}
                  color="white"
                  visible={true}
                  ariaLabel="loading-indicator"
                  secondaryColor="white"
                />
              ) : (
                "Register"
              )}
            </button>
          </div>
        </form>
        <div className="text-center">
          <p className="mt-2 text-sm text-gray-600">
            Already have an account?{" "}
            <a
              href="/"
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              Sign In
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Registration;
